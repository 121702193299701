import React from "react";
import Spline from "@splinetool/react-spline";
import { Link } from "react-router-dom";
import styles from "./Privacy.module.css"; // Импортируем CSS модуль

function Privacy() {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <Link to="/" className={styles["logo-link"]}>
          <h1>MaxSimple</h1>
        </Link>
        <a href="#" className={styles.btn}>
          Войти
        </a>
      </header>

      <main className={styles.content}>
        <div className={styles["spline-scene"]}>
          <Spline scene="https://prod.spline.design/AlB0ZveuUicVjRKr/scene.splinecode" />
        </div>
        <div className={styles["text-section-wrapper"]}>
          <div className={styles["text-section"]}>
            <h1>Политика в отношении обработки персональных данных</h1>
            <h2>1. Общие положения</h2>
            <p>
              Настоящая политика обработки персональных данных составлена в
              соответствии с требованиями Федерального закона от 27.07.2006. №
              152-ФЗ «О персональных данных» (далее — Закон о персональных
              данных) и определяет порядок обработки персональных данных и меры
              по обеспечению безопасности персональных данных, предпринимаемые
              Михайловым Иваном Сергеевичем (далее — Оператор).
            </p>
            <p>
              1.1. Оператор ставит своей важнейшей целью и условием
              осуществления своей деятельности соблюдение прав и свобод человека
              и гражданина при обработке его персональных данных, в том числе
              защиты прав на неприкосновенность частной жизни, личную и семейную
              тайну.
            </p>
            <p>
              1.2. Настоящая политика Оператора в отношении обработки
              персональных данных (далее — Политика) применяется ко всей
              информации, которую Оператор может получить о посетителях
              веб-сайта{" "}
              <a href="https://thismywebsite.com">https://thismywebsite.com</a>.
            </p>
            <h2>2. Основные понятия, используемые в Политике</h2>
            <p>
              2.1. Автоматизированная обработка персональных данных — обработка
              персональных данных с помощью средств вычислительной техники.
            </p>
            <p>
              2.2. Блокирование персональных данных — временное прекращение
              обработки персональных данных (за исключением случаев, если
              обработка необходима для уточнения персональных данных).
            </p>
            <p>
              2.3. Веб-сайт — совокупность графических и информационных
              материалов, а также программ для ЭВМ и баз данных, обеспечивающих
              их доступность в сети интернет по сетевому адресу
              <a href="https://thismywebsite.com">https://thismywebsite.com</a>.
            </p>
            <p>
              2.4. Информационная система персональных данных — совокупность
              содержащихся в базах данных персональных данных и обеспечивающих
              их обработку информационных технологий и технических средств.
            </p>
            <p>
              2.5. Обезличивание персональных данных — действия, в результате
              которых невозможно определить без использования дополнительной
              информации принадлежность персональных данных конкретному
              Пользователю или иному субъекту персональных данных.
            </p>
            <p>
              2.6. Обработка персональных данных — любое действие (операция) или
              совокупность действий (операций), совершаемых с использованием
              средств автоматизации или без использования таких средств с
              персональными данными, включая сбор, запись, систематизацию,
              накопление, хранение, уточнение (обновление, изменение),
              извлечение, использование, передачу (распространение,
              предоставление, доступ), обезличивание, блокирование, удаление,
              уничтожение персональных данных.
            </p>
            <p>
              2.7. Оператор — государственный орган, муниципальный орган,
              юридическое или физическое лицо, самостоятельно или совместно с
              другими лицами организующие и/или осуществляющие обработку
              персональных данных, а также определяющие цели обработки
              персональных данных, состав персональных данных, подлежащих
              обработке, действия (операции), совершаемые с персональными
              данными.
            </p>
            <p>
              2.8. Персональные данные — любая информация, относящаяся прямо или
              косвенно к определенному или определяемому Пользователю веб-сайта{" "}
              <a href="https://thismywebsite.com">https://thismywebsite.com</a>.
            </p>
            <p>
              2.9. Персональные данные, разрешенные субъектом персональных
              данных для распространения, — персональные данные, доступ
              неограниченного круга лиц к которым предоставлен субъектом
              персональных данных путем дачи согласия на обработку персональных
              данных, разрешенных субъектом персональных данных для
              распространения в порядке, предусмотренном Законом о персональных
              данных (далее — персональные данные, разрешенные для
              распространения).
            </p>
            <p>
              2.10. Пользователь — любой посетитель веб-сайта{" "}
              <a href="https://thismywebsite.com">https://thismywebsite.com</a>.
            </p>
            <p>
              2.11. Предоставление персональных данных — действия, направленные
              на раскрытие персональных данных определенному лицу или
              определенному кругу лиц.
            </p>
            <p>
              2.12. Распространение персональных данных — любые действия,
              направленные на раскрытие персональных данных неопределенному
              кругу лиц (передача персональных данных) или на ознакомление с
              персональными данными неограниченного круга лиц, в том числе
              обнародование персональных данных в средствах массовой информации,
              размещение в информационно-телекоммуникационных сетях или
              предоставление доступа к персональным данным каким-либо иным
              способом.
            </p>
            <p>
              2.13. Трансграничная передача персональных данных — передача
              персональных данных на территорию иностранного государства органу
              власти иностранного государства, иностранному физическому или
              иностранному юридическому лицу.
            </p>
            <p>
              2.14. Уничтожение персональных данных — любые действия, в
              результате которых персональные данные уничтожаются безвозвратно с
              невозможностью дальнейшего восстановления содержания персональных
              данных в информационной системе персональных данных и/или
              уничтожаются материальные носители персональных данных.
            </p>
          </div>
          <div className={styles["fade-overlay"]}></div>
        </div>
      </main>

      <footer className={styles.footer}>
        <p>2024 © ООО «Максимпл»</p>
        <div className={styles.links}>
          <Link to="/terms" className={styles.link}>
            Условия и положения
          </Link>
          <Link to="/privacy" className={styles.link}>
            Политика конфиденциальности
          </Link>
        </div>
      </footer>
    </div>
  );
}

export default Privacy;

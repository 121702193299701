import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home/Home";
import Terms from "./components/Terms/Terms"; // Страница Условия и положения
import Privacy from "./components/Privacy/Privacy"; // Страница Политика конфиденциальности
import "./global.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/terms" element={<Terms />} />{" "}
        {/* Маршрут для страницы Условия и положения */}
        <Route path="/privacy" element={<Privacy />} />{" "}
        {/* Маршрут для страницы Политика конфиденциальности */}
      </Routes>
    </Router>
  );
}

export default App;
